const responseMessages = {
  signupMessage:
    "Welcome to igcarts, you have successfully created an account!",
  loginMessage: "Login successfully!",
  // remove login message on successful. it redirect to dashboard without
  loginErrorMessage: "Email or password incorrect, check and try again",
  signupErrorMessage:
    "The credentials were invalid or already in use. Please change your details.",
  signupErrorMessage2: "Unable to sign up please try again or contact support",
  isAvailableOrderMessage: "Order has been processed successfully.",
  notAvailableOrderMessage: "Order placed successfully",
  orderFailure: "Failed to place order. Please try again",
  errorMessageForCatchBlock:
    "An error occurred while processing, please try again or contact support",
  errorMessageForCatchBlock2:
    "An error has occurred. Please check your network and try again later",
  passwordResetMessage:
    "Password reset link have been sent to your mail successfully",
  linkClickMessage: "Click on a media before submitting",
  unavailableServiceDuetoId:
    "This service is currently not available, contact support",
  adminLoginFailedMessage:
    "Failed to login, check your login credentials and try again",
  passwordResetFail:
    "Failed to set reset link, check your network settings and try again",
  newPasswordSuccess: "Password reset successfully",
  newPasswordFails:
    "Password reset failed, request for another link and try again",
  errorMessage: "An error has occured while trying to process your request",
  paymentError:
    "An error has occured while processing your payment, please try again",
  passwordUpdate: "Password updated successfully",
  ticketSuccess: "Your complain has been received and will be resolved soon.",
  ticketFailure:
    "Your complain could not be submitted at the moment please try again.",
  insufficientBalance:
    "Your balance is not enough to complete your request, please deposit and try again.",
  userNotFound: "You do not have an account, please sign up first",
  minimumQuantityOf10: "Quantity can not be less than 10",
  minimumQuantityOf100: "Quantity can not be less than 100",
  minimumQuantityOf1000: "Quantity can not be less than 1000",
};

export default responseMessages;
