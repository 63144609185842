export const Threads = [
  {
    categoryName: "Threads",
    items: [
      { name: "Thread Followers", path: "/thread_followers" },
      { name: "Thread Likes", path: "/thread_likes" },
      { name: "Thread Comments", path: "/thread_comment" },
    ],
  },
];
export const IGLikes = [
  {
    categoryName: "IG Likes",
    items: [
      {
        name: "High Quality Fast Likes - None Drop",
        path: "/ig_high_quality_fast_likes",
      },
      {
        name: "IG Likes",
        path: "/instagram_likes",
      },
      {
        name: "Real Organic Likes (Female/Male/Mix)",
        path: "/ig_real_oganic_likes",
      },
      {
        name: "Auto High Quality - None Drops Likes",
        path: "/ig_auto_high_quality_none_drop_likes",
      },
      {
        name: "Auto Likes",
        path: "/ig_auto_likes",
      },
      {
        name: "Auto Real Likes",
        path: "/ig_auto_real_likes",
      },
    ],
  },
];
export const IGViews = [
  {
    categoryName: "IG Views",
    items: [
      { name: "Views", path: "/instagram_views" },
      { name: "IGTV Views", path: "/ig-tv_views" },
      { name: "Auto Views", path: "/ig_auto_views" },
      // {
      //   name: "Views Plus Impressions",
      //   path: "/ig_views_plus_impressions",
      // },
      // {
      //   name: "Auto Views Plus Impressions",
      //   path: "/ig_auto_views_plus_impression",
      // },
      { name: "Story Views", path: "/ig_story_views" },
      { name: "IG Lives View", path: "/ig_lives_view" },
    ],
  },
];
export const Instagram = [
  {
    categoryName: "Instagram",
    items: [
      {
        name: "Real USA Followers - None Drop (Female/Male/Mix)",
        path: "/ig_real_usa_followers",
      },
      {
        name: "Real Organic Comments (Female/Male/Mix)",
        path: "/ig_real_organic_comments",
      },
      {
        name: "Real Organic USA Story Views",
        path: "/ig_real_organic_usa_story_views",
      },
      {
        name: "Comments From Verified Accounts",
        path: "/ig_comments_from_verified_accounts",
      },
      {
        name: "Custom Manual Real Comments",
        path: "/ig_custom_manually_real_comments",
      },
      {
        name: "Auto Real Comments",
        path: "/ig_auto_real_comments",
      },
      {
        name: "Auto Daily Real USA Followers - None Drop",
        path: "/ig_auto_daily_real_usa_followers",
      },
      {
        name: "Followers",
        path: "/ig_followers",
      },
      {
        name: "Auto Daily Followers",
        path: "/ig_auto_daily_followers",
      },
      {
        name: "IG Real Engagements",
        path: "/ig_real_engagements",
      },
      {
        name: "Auto Real Engagements",
        path: "/ig_auto_real_engagements",
      },
      {
        name: "Auto IG Real Package",
        path: "/auto_ig_real_comments_and_likes",
      },
      {
        name: "Impressions",
        path: "/ig_impressions",
      },
      {
        name: "Mentions",
        path: "/instagram_mentions",
      },
      {
        name: "IG Saves",
        path: "/ig_saves",
      },
      {
        name: "IG Profile Visits",
        path: "/ig_profile_visits",
      },
    ],
  },
];
export const IGReels = [
  {
    categoryName: "IG Reels",
    items: [
      { name: "IG Reels Views", path: "/ig_reels_views" },
      { name: "IG Reels Likes", path: "/ig_reels_likes" },
      {
        name: "IG Reels Real Organic Likes",
        path: "/ig_reels_real_organic_likes",
      },
      {
        name: "IG Reels Real Organic Comments",
        path: "/ig_reels_real_organic_comments",
      },
      {
        name: "IG Reels Views - Link",
        path: "/ig_reels_views-link",
      },
      {
        name: "IG Reels Likes - Link",
        path: "/ig_reels_likes-link",
      },
    ],
  },
];
export const TikTok = [
  {
    categoryName: "TikTok",
    items: [
      { name: "TikTok Comments", path: "/tiktok_comments" },
      { name: "TikTok Views", path: "/tiktok_views" },
      { name: "TikTok Likes", path: "/tiktok_likes" },
      { name: "TikTok Followers", path: "/tiktok_followers" },
      { name: "TikTok Views (Link)", path: "/tiktok_views-link" },
      { name: "TikTok Likes - (Link)", path: "/tiktok_likes-link" },
    ],
  },
];

export const Facebook = [
  {
    categoryName: "Facebook",
    items: [
      { name: "Facebook Post Likes", path: "/facebook_post_likes" },
      { name: "Facebook Page Likes", path: "/facebook_page_likes" },
      { name: "Facebook Video Views", path: "/facebook_video_views" },
    ],
  },
];
export const YoutubeSpotifySoundCloud = [
  {
    categoryName: "Youtube/Spotify/SoundCloud",
    items: [
      { name: "Youtube Views", path: "/youtube_views" },
      { name: "Youtube Likes", path: "/youtube_likes" },
      { name: "Youtube Subscribers", path: "/youtube_subscribers" },
      { name: "SoundCloud Likes", path: "/soundcloud_likes" },
      { name: "SoundCloud Play", path: "/soundcloud_play" },
      { name: "Spotify Plays", path: "/spotify_plays" },
      { name: "Spotify Followers", path: "/spotify_followers" },
      {
        name: "Spotify - Playlist Followers",
        path: "/spotify_playlist_followers",
      },
      { name: "Twitter ( X ) - Followers", path: "/twitter_followers" },
    ],
  },
];
export const Engagements = [
  {
    categoryName: "Engagements",
    items: [
      {
        name: "Real comments From Your Followers",
        path: "/ig_real_comments_from_your_followers",
      },
      {
        name: "Real Likes from Your Followers",
        path: "/ig_real_likes_from_your_followers",
      },
    ],
  },
];
export const IGAiGrowthPackage = [
  {
    categoryName: "IG AI Growth-Package",
    items: [{ name: "Account Growth", path: "/ig_ai_account_growth" }],
  },
];
