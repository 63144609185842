import React from "react";
import Table from "../../../../../components/shared/Table";

const AutoServicesTable = ({ autoServiceData, handleRemove }) => {
  const autoServicesDetails = [
    {
      name: "Service Name",
      selector: (row) => row.service_name,
      width: "130px",
    },
    { name: "IgC. Username", selector: (row) => row.username, width: "160px" },
    {
      name: "Last post",
      selector: (row) => (
        <a href={row.last_post} target="_blank" rel="noopener noreferrer">
          {row.last_post}
        </a>
      ),
      width: "240px",
    },
    {
      name: "Username",
      selector: (row) => row.social_username,
      width: "120px",
    },
    { name: "Post Done", selector: (row) => row.post_done, width: "100px" },
    {
      name: "No. of Post",
      selector: (row) => row.no_of_posts,
      width: "120px",
    },
    { name: "Duration", selector: (row) => row.duration, width: "90px" },
    { name: "Qty.", selector: (row) => row.quantity, width: "90px" },
    {
      name: "Delete",
      selector: (row) => (
        <button
          onClick={() => handleRemove(row)}
          className="text-red-700 italic"
        >
          Delete
        </button>
      ),
      width: "80px",
    },
  ];

  return (
    <Table
      title="Auto Services"
      column={autoServicesDetails}
      data={autoServiceData}
    />
  );
};

export default AutoServicesTable;
