import React from "react";
import { Select, Input } from "antd";
import Button from "../../../../../components/shared/Button";

const { Option } = Select;

const AutoServicesForm = ({
  autoServicePost,
  handleInputChange,
  addAutoSubscriptionService,
  loading,
  services,
  handleServiceSelect,
  handleDurationSelect,
}) => {
  return (
    <form onSubmit={addAutoSubscriptionService} className="mt-5">
      <div className="xl:grid xl:grid-cols-3 flex flex-wrap justify-center gap-4 mb-5 sm:flex-col xs:flex-col md:flex-wrap lg:flex-wrap sm:items-center xs:items-center md:items-center lg:items-center">
        <Select
          value={autoServicePost.service_name}
          onChange={handleServiceSelect}
          placeholder="Select Service"
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        >
          {services.map((service) => (
            <Option key={service.id} value={service.service_name}>
              {service.service_name}
            </Option>
          ))}
        </Select>
        <Input
          type="text"
          name="username"
          placeholder="Username"
          value={autoServicePost.username}
          onChange={handleInputChange}
          required
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <Input
          type="text"
          name="social_username"
          placeholder="Social Media Username"
          value={autoServicePost.social_username}
          onChange={handleInputChange}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <Input
          type="text"
          name="no_of_posts"
          placeholder="Number of Posts"
          value={autoServicePost.no_of_posts}
          onChange={handleInputChange}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
        <Select
          name="duration"
          onChange={handleDurationSelect}
          value={autoServicePost.duration}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        >
          <Option value="30">30</Option>
          <Option value="60">60</Option>
          <Option value="120">120</Option>
        </Select>
        <Input
          type="text"
          name="quantity"
          placeholder="Quantity"
          value={autoServicePost.quantity}
          onChange={handleInputChange}
          className="shadow-sm bg-white outline-none border border-gray-300 p-1 rounded-md sm:w-full xs:w-full md:w-full"
        />
      </div>
      <div className="flex justify-center mt-6">
        <Button
          type="submit"
          title={loading ? "Creating Service..." : "Create Service"}
          size="md"
          disabled={loading}
        />
      </div>
    </form>
  );
};

export default AutoServicesForm;
