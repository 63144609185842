import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Button from "../../components/shared/Button";
import { useDispatch } from "react-redux";
import { signupUser } from "../../store/Slice/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import responseMessages from "../../utils/responseMessages";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: "",
    user_name: "",
    password: "",
    referral_code: "",
  };
  const [error, setError] = useState(false);
  const [errorTwo, setErrorTwo] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string().label("Email").email("Invalid email").required(),
    user_name: Yup.string().label("Username").required(),
    password: Yup.string().label("Password").required(),
    referral_code: Yup.string().label("Referral Code").optional(),
  });

  const handleSignup = async (values, { resetForm }) => {
    try {
      setIsLoading(true);
      const modifiedValues = {
        ...values,
        referral_code: values.referral_code || "unknown",
      };
      const response = await dispatch(signupUser(modifiedValues));
      if (response.payload.code === 200 || response.payload.code === 201) {
        toast.success(responseMessages.signupMessage, {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
        resetForm();
      } else {
        if (response.payload.message === "Sorry, unable to register") {
          setErrorTwo(true);
        } else {
          setError(true);
        }
      }
      setIsLoading(false);
    } catch (error) {}
  };

  return (
    <div className="flex justify-center items-center py-12">
      <div className="w-full max-w-md lg:px-4 md:px-3 sm:px-2 xs:px-2 bg-white rounded-md shadow-md">
        <p className="text-center font-FiraSans font-semibold text-2xl lg:text-xl md:text-xl sm:text-lg xs:text-lg py-3">
          Register on Igcarts
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSignup}
        >
          {({ handleSubmit, isValid }) => (
            <Form
              className="px-8 lg:px-5 md:px-4 sm:px-3 xs:px-2 py-4"
              onSubmit={handleSubmit}
            >
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block text-black text-sm font-FiraSans font-bold mb-2"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="user@email.com"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                />
                <ErrorMessage
                  name="email"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="user_name"
                  className="block text-black text-sm font-FiraSans font-bold mb-2"
                >
                  Username
                </label>
                <Field
                  type="text"
                  name="user_name"
                  id="user_name"
                  placeholder="user1234"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                />
                <ErrorMessage
                  name="user_name"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="password"
                  className="block text-black text-sm font-FiraSans font-bold mb-2"
                >
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                />
                <ErrorMessage
                  name="password"
                  component="p"
                  className="text-red-500 text-sm italic"
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="referral_code"
                  className="block text-black text-sm font-FiraSans font-bold mb-2"
                >
                  Referral Code
                </label>
                <Field
                  type="text"
                  name="referral_code"
                  id="referral_code"
                  placeholder="Referral Code"
                  className={`shadow appearance-none border font-FiraSans rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                />
              </div>

              <div className="flex items-center justify-center mt-6">
                <Button
                  type="submit"
                  title={isLoading ? "Signing up..." : "Create an Account"}
                  variant={isValid ? "primary" : "disabled"}
                  disabled={!isValid || isLoading}
                />
              </div>
              {error && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.signupErrorMessage}
                </p>
              )}
              {errorTwo && (
                <p className="text-center text-red-500 text-sm italic">
                  {responseMessages.signupErrorMessage2}
                </p>
              )}
              <div className="text-center mt-3">
                <span className="text-blue-800 hover:text-blue-500 font-FiraSans text-xs font-normal">
                  Already have an account?{" "}
                  <Link
                    to="/signin"
                    className="text-blue-800 hover:text-blue-500 font-FiraSans font-semibold transition ease-in-out duration-300"
                  >
                    Signin
                  </Link>
                </span>
              </div>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Index;
